<template>
  <div class="chat-screen-container">
    <div class="chat-screen">
      <!-- Header shows the file name if uploaded -->
      <div class="header bg-light">
        {{ selectedFile ? $t('fileLabel', { fileName: selectedFile.name }) : $t('fileUploadPrompt') }}
      </div>
      <div class="chat-box" ref="chatBox">
        <!-- Loop through messages and align them based on sender -->
        <div
            v-for="(message, index) in chatMessages"
            :key="index"
            class="message-wrapper"
            :class="messageAlignment(message)"
        >
          <span class="message-label" v-if="message.sender === 'user'">{{$t('you')}}</span>
          <span class="message-label" v-else>{{$t('api')}}</span>
          <div class="message-content text-dark" v-html="formatMessage(message.text)"></div>

          <div v-if="message.isOCRMessage" class="ocr-download">
            <button @click="downloadDocx" class="btn-secondary mdi mdi-arrow-down">
              {{ $t('downloadFile') }}
            </button>
          </div>

          <div v-if="message.alternatives" class="alternatives">
            <div
                v-for="(alternative, idx) in message.alternatives"
                :key="idx"
                class="alternative-item"
                @click="sendAlternativeQuestion(alternative)"
            >
              {{ alternative }}
            </div>
          </div>
        </div>

        <!-- Show file uploading message inside chat board -->
        <div v-if="isUploading" class="message-wrapper api-message">
          <span class="message-label">{{ $t('api') }}</span>
          <div class="message-content">
            {{ $t('uploadingFile') }}
            <div class="loader-animation"></div>
          </div>
        </div>

        <!-- Show loader and message while waiting for response inside chat board -->
        <div v-if="isSendingMessage" class="message-wrapper api-message">
          <span class="message-label">{{ $t('api') }}</span>
          <div class="message-content text-dark">
            {{ $t('waitingForResponse') }}
            <div class="loader-animation"></div>
          </div>
        </div>
      </div>

      <div class="input-box bg-light">
        <!-- PDF Icon for File Upload -->
        <label class="file-upload">
          <input
              type="file"
              @change="onFileChange"
              class="file-input"
              accept="application/pdf"
          />
          <img src="@/assets/pdf-icon.png" alt="Upload PDF" class="pdf-icon" />
        </label>

        <!-- Show file name in the header instead of status -->
        <input
            type="text"
            :placeholder="(selectedFile || isUploading) ? $t('typeMessage') : $t('typeMessage.disabled')"
            v-model="newMessage"
            @keyup.enter="sendMessage"
            :disabled="!selectedFile || isUploading"
            :class="{ 'disabled-input': !selectedFile || isUploading }"
            class="text-input bg-light"
        />
        <button
            @click="sendMessage"
            :disabled="!selectedFile || isUploading"
            :class="{ 'disabled-button': !selectedFile || isUploading }"
            class="text-dark"
        >
          {{ $t('send') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/api/axiosInstance";

export default {
  data() {
    return {
      chatMessages: [],
      newMessage: '',
      selectedFile: null,
      isUploading: false,
      isSendingMessage: false,
      uploadUuid: null,
      uploadPollingInterval: null,
    };
  },
  methods: {
    sendMessage() {
      if (this.newMessage.trim() === '') return;

      this.chatMessages.push({ text: this.newMessage, sender: 'user' });
      this.isSendingMessage = true;

      this.$nextTick(() => {
        this.scrollToBottom();
      });

      axiosInstance.post('/chat', { message: this.newMessage })
          .then((response) => {
            const backendResponse = response.data['response'];
            let alternatives = null; // = this.extractAlternatives(backendResponse);

            let messageText = backendResponse.split('#ALTERNATIVE')[0].trim();

            this.chatMessages.push({
              text: messageText,
              sender: 'api',
              alternatives: alternatives,
            });

            this.isSendingMessage = false;
            this.$nextTick(() => {
              this.scrollToBottom();
            });
          })
          .catch((error) => {
            this.isSendingMessage = false;
            console.error('Error sending message:', error);
          });

      this.newMessage = '';
    },
    downloadDocx() {
      axiosInstance({
        url: '/chat/downloadDocx',
        method: 'GET',
        responseType: 'blob',
      })
          .then((response) => {
            const blob = new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            });

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = this.selectedFile.name.replace(/\.pdf$/, '.docx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch((error) => {
            console.error('Error downloading DOCX:', error);
          });
    },
    onFileChange(event) {
      this.selectedFile = event.target.files[0];

      if (this.selectedFile) {
        this.uploadFile();
      }
    },
    uploadFile() {
      if (!this.selectedFile) return;

      const formData = new FormData();
      formData.append('file', this.selectedFile);
      this.isUploading = true;

      const longRequestTimeout = setTimeout(() => {
        this.chatMessages.push({
          text: this.$t('longRequest'),
          sender: 'api'
        });
      }, 7000);

      axiosInstance.post('/pdf/uploadPdf', formData, {
        headers: {'Content-Type': 'multipart/form-data'},
      })
          .then((response) => {
            clearTimeout(longRequestTimeout);

            this.uploadUuid = response.data; // Extract the UUID from the response
            this.chatMessages.push({
              text: this.$t('fileUploaded', {fileName: this.selectedFile.name}),
              sender: 'user'
            });

            this.pollUploadStatus();  // Start polling for the upload status
          })
          .catch((error) => {
            clearTimeout(longRequestTimeout);
            this.chatMessages.push({
              text: this.$t('uploadError'),
              sender: 'api'
            });
            this.isUploading = false;
            console.error('Error uploading file:', error);
          });
    },
    pollUploadStatus() {
      let uploadLogs = [];
      this.uploadPollingInterval = setInterval(() => {
        axiosInstance.get(`/pdf/uploadStatus/${this.uploadUuid}`)
            .then((response) => {
              console.log('Upload status:', response.data)
              if (response.data['uploaded'] === true) {
                clearInterval(this.uploadPollingInterval);
                this.isUploading = false;
              }
              if (uploadLogs.length < response.data['processingLogs'].length) {
                uploadLogs = response.data['processingLogs'];
                this.chatMessages.push({
                  text: this.$t(uploadLogs[uploadLogs.length - 1]),
                  sender: 'api'
                });
              }

            })
            .catch((error) => {
              console.error('Error checking upload status:', error);
              clearInterval(this.uploadPollingInterval);
            });
      }, 5000); // Poll every 5 seconds
    },
    messageAlignment(message) {
      return message.sender === 'user' ? 'user-message' : 'api-message';
    },
    formatMessage(text) {
      return text.replace(/\n/g, '<br>');
    },
    scrollToBottom() {
      const chatBox = this.$refs.chatBox;
      chatBox.scrollTop = chatBox.scrollHeight;
    }
  },
  props: {
    msg: {
      type: String,
      default: 'Welcome to the chat!',
    },
  },
  mounted() {
    this.scrollToBottom();
  },
};
</script>


<style scoped>

@import '@/views/chatscreen/ChatScreen.css';

.notification {
  color: #ff9800;
  margin: 10px 0;
  text-align: center;
}

.error-message {
  color: red;
  margin: 10px 0;
  text-align: center;
}


</style>
