<script setup>
import { ref } from "vue";
import axiosInstance from "@/api/axiosInstance";
import { useAuthStore } from "@/stores/auth";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

// i18n instance
const { t } = useI18n();

const loginRequest = ref({
  email: "",
  password: "",
});
const errorMessage = ref("");
const sessionExpired = ref(false);
const authStore = useAuthStore();
const router = useRouter();

async function login() {
  try {
    // reset the error message
    clearMessages();

    // send the login request to the server
    const response = await axiosInstance.post(
        "auth/authenticate", // the endpoint
        loginRequest.value, // the request body
        { withCredentials: true }
    );

    // get the token from the response
    const accessToken = response.data.access_token;
    const refreshToken = response.data.refresh_token;

    // set the token in local storage
    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("refresh_token", refreshToken);

    // update the authorization header
    axiosInstance.defaults.headers.common[
        "Authorization"
        ] = `Bearer ${accessToken}`;

    // extract the user role from the token
    const userRoles = extractUserRoleFromToken(accessToken);

    // call the store's login method to update the state
    authStore.login(userRoles);

    // redirect to the home page
    await router.push("/chatScreen");
  } catch (error) {
    if (error.response) {
      showErrorMessage(error.response.data.message);
    } else if (error.request) {
      showErrorMessage(t("login.connectionError"));
    } else {
      showErrorMessage(t("login.generalError"));
    }
  }
}

function clearMessages() {
  errorMessage.value = "";
  sessionExpired.value = false;
}

function showErrorMessage(message) {
  errorMessage.value = message;
}

function extractUserRoleFromToken(token) {
  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  return decodedToken.roles;
}

// Check if the route query parameter "sessionExpired" is present
if (router.currentRoute.value.query.sessionExpired) {
  sessionExpired.value = true;
}
</script>

<template>
  <section class="py-4 py-md-5 my-5">
    <div class="container py-md-5">
      <div class="row">
        <div class="col-md-6 text-center">
          <!-- <img class="img-fluid w-100" src="@/assets/img/illustrations/login.svg" alt="login-img" /> -->
        </div>
        <div class="col-md-5 col-xl-4 text-center text-md-start">
          <h2 class="display-6 fw-bold mb-5">
            <span class="underline pb-1">
              <strong>{{ $t('login.title') }}</strong>
            </span>
          </h2>
          <form @submit.prevent="login">
            <div class="mb-3">
              <input
                  class="shadow form-control"
                  v-model="loginRequest.email"
                  required="required"
                  type="email"
                  name="email"
                  :placeholder="$t('login.emailPlaceholder')"
              />
            </div>
            <div class="mb-3">
              <input
                  class="shadow form-control"
                  v-model="loginRequest.password"
                  required="required"
                  type="password"
                  name="password"
                  :placeholder="$t('login.passwordPlaceholder')"
              />
            </div>
            <div class="mb-5">
              <button class="btn btn-dark shadow" type="submit">
                {{ $t('login.signInButton') }}
              </button>
            </div>
            <div v-if="errorMessage" class="alert alert-danger">
              {{ errorMessage }}
            </div>
            <div v-if="sessionExpired" class="alert alert-warning">
              {{ $t('login.sessionExpired') }}
            </div>
            <p class="text-muted">
              {{ $t('login.noAccount') }}
              <router-link to="/signup">
                {{ $t('login.signUp') }}
                <img src="@/assets/img/arrow-right.svg" :alt="$t('login.arrowAlt')" />
              </router-link>
            </p>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
