<template>
  <div class="mt-4 p-4 border rounded shadow">
    <form>
      <!-- Keyword Search -->
      <div class="mb-3">
        <label for="keyword" class="form-label">Aranacak Kelime:</label>
        <input
            type="text"
            id="keyword"
            class="form-control"
            v-model="keyword"
            placeholder="Örn. Hukuk, dava, ihale, borç ..."
        />

      </div>
      <button type="button" class="btn btn-link" @click="toggleDetailedSearch">
        {{
          showDetailedSearch ? 'Hide Detailed Search' : 'Show Detailed Search'
        }}
      </button>

      <!-- Dropdowns -->
      <!-- Kurullar Dropdown -->
      <div v-if="showDetailedSearch" class="mt-3">
        <small v-if="!selectedUnit" class="text-muted">***Birim seçilmediğinde
          tüm birimlerde arama yapılır</small>
        <div class="row">
          <div class="mb-3 col-md-4">
            <label for="kurul" class="form-label">Kurullar:</label>
            <select id="kurul" class="form-select" v-model="selectedKurul">
              <option value="" disabled selected>Kurul seçiniz</option>
              <option v-for="kurul in kurullar" :key="kurul" :value="kurul">{{
                  kurul
                }}
              </option>
            </select>
          </div>

          <!-- Hukuk Daireleri Dropdown -->
          <div class="mb-3 col-md-4">
            <label for="hukukDairesi" class="form-label">Hukuk
              Daireleri:</label>
            <select id="hukukDairesi" class="form-select"
                    v-model="selectedHukukDairesi">
              <option value="" disabled selected>Hukuk Dairesi seçiniz</option>
              <option v-for="daire in hukukDaireleri" :key="daire"
                      :value="daire">
                {{ daire }}
              </option>
            </select>
          </div>

          <!-- Ceza Daireleri Dropdown -->
          <div class="mb-3 col-md-4">
            <label for="cezaDairesi" class="form-label">Ceza Daireleri:</label>
            <select id="cezaDairesi" class="form-select"
                    v-model="selectedCezaDairesi">
              <option value="" disabled selected>Ceza Dairesi seçiniz</option>
              <option v-for="daire in cezaDaireleri" :key="daire"
                      :value="daire">
                {{ daire }}
              </option>
            </select>
          </div>
        </div>
        <!-- Esas Numarası -->
        <div class="mb-3">
          <label class="form-label">Esas Numarası:</label>
          <div class="input-group">
            <input type="text" class="form-control" placeholder="Esas yılı"
                   v-model="esasYili"/>
            <span class="input-group-text">/</span>
            <input type="text" class="form-control" placeholder="İlk sıra no"
                   v-model="ilkSiraNo"/>
            <span class="input-group-text">-</span>
            <input type="text" class="form-control" placeholder="Son sıra no"
                   v-model="sonSiraNo"/>
          </div>
        </div>

        <!-- Karar Numarası -->
        <div class="mb-3">
          <label class="form-label">Karar Numarası:</label>
          <div class="input-group">
            <input type="text" class="form-control" placeholder="Karar yılı"
                   v-model="kararYili"/>
            <span class="input-group-text">/</span>
            <input type="text" class="form-control" placeholder="İlk sıra no"
                   v-model="ilkKararNo"/>
            <span class="input-group-text">-</span>
            <input type="text" class="form-control" placeholder="Son sıra no"
                   v-model="sonKararNo"/>
          </div>
        </div>

        <!-- Karar Tarihi -->
        <div class="mb-3">
          <label class="form-label">Karar Tarihi:</label>
          <div class="input-group">
            <input type="date" class="form-control" v-model="baslangicTarihi"/>
            <span class="input-group-text">-</span>
            <input type="date" class="form-control" v-model="bitisTarihi"/>
          </div>
        </div>

        <!-- Sorting Radio Buttons -->
        <div class="mb-3">
          <label class="form-label">Sıralama:</label>
          <div class="form-check">
            <input
                class="form-check-input"
                type="radio"
                id="esasNo"
                value="esas"
                v-model="siralama"
            />
            <label for="esasNo" class="form-check-label">Esas No'ya Göre</label>
          </div>
          <div class="form-check">
            <input
                class="form-check-input"
                type="radio"
                id="kararNo"
                value="karar"
                v-model="siralama"
            />
            <label for="kararNo" class="form-check-label">Karar No'ya
              Göre</label>
          </div>
          <div class="form-check">
            <input
                class="form-check-input"
                type="radio"
                id="kararTarih"
                value="tarih"
                v-model="siralama"
            />
            <label for="kararTarih" class="form-check-label">Karar Tarihine
              Göre</label>
          </div>
        </div>

        <!-- Sorting Order -->
        <div class="mb-3">
          <label class="form-label">Yön:</label>
          <div class="form-check">
            <input
                class="form-check-input"
                type="radio"
                id="buyuktenKucuge"
                value="desc"
                v-model="yon"
            />
            <label for="buyuktenKucuge" class="form-check-label">Büyükten Küçüğe
              Göre</label>
          </div>
          <div class="form-check">
            <input
                class="form-check-input"
                type="radio"
                id="kucuktenBuyuge"
                value="asc"
                v-model="yon"
            />
            <label for="kucuktenBuyuge" class="form-check-label">Küçükten Büyüğe
              Göre</label>
          </div>
        </div>
      </div>
      <!-- Buttons -->
      <div class="mb-3">
        <button type="button" @click="clearForm" class="btn btn-danger me-2">
          Temizle
        </button>
        <button type="button" @click="fetchData" class="btn btn-success">Ara
        </button>
      </div>
      <div class="mt-4">
        <div class="row">
          <ul v-if="responseData.data" class="col-md-4">
            <li class="btn-light" v-for="item in responseData.data"
                :key="item.id" @click="fetchDokuman(item.id)">
              <strong>Daire:</strong> {{ item.daire }} |
              <strong>Esas:</strong> {{ item.esasNo }} |
              <strong>Karar:</strong> {{ item.kararNo }} |
              <strong>Karar Tarihi:</strong> {{ item.kararTarihi }}
            </li>
          </ul>
          <div v-if="isLoadingDoc" class="text-danger col-md-8">
            <div class="text-dark">
              {{ $t('document.loading') }}
              <div class="loader-animation"></div>
            </div>
          </div>
          <div class="col-md-8" v-if="dokumanText">
            <!-- Nav tabs -->
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="summary-tab" data-bs-toggle="tab" data-bs-target="#summary" type="button" role="tab" aria-controls="summary" aria-selected="true">  {{$t('document.summary')}}</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="dokuman-tab" data-bs-toggle="tab" data-bs-target="#dokuman" type="button" role="tab" aria-controls="dokuman" aria-selected="false">{{$t('document.text')}}</button>
              </li>
            </ul>

            <!-- Tab content -->
            <div class="tab-content border border-dark p-3">
              <div class="tab-pane fade show active" id="summary" role="tabpanel" aria-labelledby="summary-tab">
                <div v-html="docSummary"></div>
              </div>
              <div class="tab-pane fade" id="dokuman" role="tabpanel" aria-labelledby="dokuman-tab">
                <div v-html="dokumanText" class="border-top border-dark"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </form>
  </div>
</template>

<script>

import axiosInstance from "@/api/axiosInstance";

export default {
  data() {
    return {
      isLoadingDoc: false,
      dokumanText: '',
      docSummary:'',
      showDetailedSearch: false,
      keyword: '',
      responseData: [],
      selectedKurul: '',
      selectedHukukDairesi: '',
      selectedCezaDairesi: '',
      esasYili: '',
      ilkSiraNo: '',
      sonSiraNo: '',
      kararYili: '',
      ilkKararNo: '',
      sonKararNo: '',
      baslangicTarihi: '',
      bitisTarihi: '',
      siralama: '',
      yon: '',
      kurullar: [
        "Hukuk Genel Kurulu",
        "Ceza Genel Kurulu",
        "Ceza Daireleri Başkanlar Kurulu",
        "Hukuk Daireleri Başkanlar Kurulu",
        "Büyük Genel Kurulu"
      ],
      hukukDaireleri: [
        "1. Hukuk Dairesi",
        "2. Hukuk Dairesi",
        "3. Hukuk Dairesi",
        "4. Hukuk Dairesi",
        "5. Hukuk Dairesi",
        "6. Hukuk Dairesi",
        "7. Hukuk Dairesi",
        "8. Hukuk Dairesi",
        "9. Hukuk Dairesi",
        "10. Hukuk Dairesi",
        "11. Hukuk Dairesi",
        "12. Hukuk Dairesi",
        "13. Hukuk Dairesi",
        "14. Hukuk Dairesi",
        "15. Hukuk Dairesi",
        "16. Hukuk Dairesi",
        "17. Hukuk Dairesi",
        "18. Hukuk Dairesi",
        "19. Hukuk Dairesi",
        "20. Hukuk Dairesi",
        "21. Hukuk Dairesi",
        "22. Hukuk Dairesi",
        "23. Hukuk Dairesi"
      ],
      cezaDaireleri: [
        "1. Ceza Dairesi",
        "2. Ceza Dairesi",
        "3. Ceza Dairesi",
        "4. Ceza Dairesi",
        "5. Ceza Dairesi",
        "6. Ceza Dairesi",
        "7. Ceza Dairesi",
        "8. Ceza Dairesi",
        "9. Ceza Dairesi",
        "10. Ceza Dairesi",
        "11. Ceza Dairesi",
        "12. Ceza Dairesi",
        "13. Ceza Dairesi",
        "14. Ceza Dairesi",
        "15. Ceza Dairesi",
        "16. Ceza Dairesi",
        "17. Ceza Dairesi",
        "18. Ceza Dairesi",
        "19. Ceza Dairesi",
        "20. Ceza Dairesi",
        "21. Ceza Dairesi",
        "22. Ceza Dairesi",
        "23. Ceza Dairesi"
      ]
    };
  },
  methods: {
    clearForm() {
      // Clear form logic
    },
    search() {
      // Search logic
    },
    formatDate(date) {
      if (!date) return '';
      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    async fetchDokuman(id) {
      console.log('Fetching document with ID:', id);
      this.dokumanText = '';
      this.docSummary= '';
      this.isLoadingDoc = true;

      try {
        // Make POST request
        const response = await axiosInstance.post('/chat/ictihatOzet', id);
        console.log(response)
        this.isLoadingDoc = false;
        // Handle and store response data
        this.dokumanText = response.data.text;
        this.docSummary= response.data.summary;
      } catch (error) {
        this.isLoadingDoc = false;
        console.error('Error fetching data:', error);
        this.responseData = [];
      }
    },
    toggleDetailedSearch() {
      this.showDetailedSearch = !this.showDetailedSearch;
    },
    async fetchData() {

      // Request payload
      const requestData = {
        data: {
          arananKelime: this.keyword,
          yargitayMah: '',
          esasYil: this.esasYili,
          esasIlkSiraNo: this.ilkSiraNo,
          esasSonSiraNo: this.sonSiraNo,
          kararYil: this.kararYili,
          kararIlkSiraNo: this.ilkKararNo,
          kararSonSiraNo: this.sonKararNo,
          baslangicTarihi: this.formatDate(this.baslangicTarihi),
          bitisTarihi: this.formatDate(this.bitisTarihi),
          siralama: this.siralama ? this.siralama : "1",
          siralamaDirection: this.yon ? this.yon : 'desc',
          birimYrgKurulDaire: this.selectedKurul,
          birimYrgHukukDaire: this.selectedHukukDairesi,
          birimYrgCezaDaire: this.selectedCezaDairesi,
          pageSize: 10,
          pageNumber: 1
        }
      };
      this.responseData = [];
      this.dokumanText='';
      try {
        // Make POST request
        const response = await axiosInstance.post('/chat/ictihatAra', requestData);

        // Handle and store response data
        if (response.data && response.data.data) {
          this.responseData = response.data.data;
        } else {
          this.responseData = [];
          console.error('Unexpected response structure:', response.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        this.responseData = [];
      }
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 700px;
}
</style>
