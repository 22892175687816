import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: null,
    user: null,
  }),
  getters: {
    isUserAuthenticated(state) {
      // Check if the token exists and is valid
      return !!state.token;
    },
    isAdmin(state) {
      // Example: check if the user role is admin
      return state.user?.roles?.includes('ROLE_ADMIN') && this.isUserAuthenticated;
    },
    isLawUser(state) {
      // Example: check if the user role is a regular user
      return state.user?.roles?.includes('ROLE_USER') || this.isAdmin;
    },
    isTradeUser(state) {
      return state.user?.roles?.includes('ROLE_TRADER') || this.isAdmin;
    },
  },
  actions: {
    login(roles) {
      console.log("userRole", roles);
      this.user = {roles};
      this.token = localStorage.getItem('access_token');

      // Save user data to localStorage
      localStorage.setItem('user', JSON.stringify(this.user));
    },
    logout() {
      this.user = null;
      this.token = null;
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('user');
    },
    retrieveSession() {
      const token = localStorage.getItem('access_token');
      const user = localStorage.getItem('user');

      if (user) {
        this.user = JSON.parse(user); // Retrieve and set user state from localStorage
      }

      if (token) {
        const isTokenExpired = this.isTokenExpired(token);
        if (isTokenExpired) {
          this.logout();
        } else {
          this.token = token;
          // You can redirect the user if needed
          // router.push({ name: 'chatScreen' });
        }
      }
    },
    isTokenExpired(token) {
      const payload = JSON.parse(atob(token.split('.')[1]));
      const expiry = payload.exp;
      const now = Math.floor(Date.now() / 1000);
      return now >= expiry;
    },
  },
});
